@mixin sectionFormat {
    min-height: 100vh;
    padding: 30px 40px 0 40px;

    @media (max-width: 450px) {
        padding: 30px 10px 10px 10px;
    }
}

@mixin titles {
    text-align: center;
    font-size: 35px;
    color: $dark-blue;
    margin-bottom: 20px;
}