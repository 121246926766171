.Me {
    @include sectionFormat;
    background-color: $light-blue;
    display: grid;
    grid-template-areas: 'avatar description';
    grid-template-columns: 60% 40%;
    grid-template-rows: 1fr;
    align-items: center;

    &__avatar {
        grid-area: 'avatar';
        img {
            display: block;
            margin-left: auto;
            margin-right: auto;
            width: 70%;
        }
    }

    &__description {
        grid-area: 'description';

        &_section {
            margin: 15px 0;
            font-size: 30px;
            letter-spacing: 1px;
            
            h2 {
                font-size: 26px;
            }
        }

        p{
            font-size: 20px;
            text-align: left;
        }

        &_social {
            display: flex;
            justify-content: start;
            align-items: center;
            gap: 15px;
            padding: 10px;

            font-size: 40px;

            svg:nth-child(1) {
                color: $light-green;
            }
        }

        @media (max-width: 450px) {
            &_section {
                font-size: 25px;

                h2 {
                    font-size: 23px;
                }
            }

            p {
                font-size: 16px;
            }
        }
    }

    @media (max-width: 850px) {
        grid-template-areas: 'description' 'avatar';
        grid-template-columns: 1fr;
        justify-content: center;
    }

    @media (max-width: 500px) {
        grid-template-rows: min-max(150px, 1fr) 2fr;
    }
}