@import './variables.scss';
@import './mixins.scss';
@import './sections/me.scss';
@import './sections/experience.scss';
@import './sections/portfolio.scss';
@import './components/Navbar.scss';
@import './components/Modal.scss';

@import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600&display=swap');

* {
    margin: 0;
    padding: 0;
}

html {
    scroll-behavior: smooth;
}

h1, h2, h3, h4 {
    font-family: 'Montserrat', sans-serif;
}

div, p {
    font-family: 'Lato', sans-serif;
}