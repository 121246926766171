.Experience {
    @include sectionFormat;

    &__title {
        @include titles;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 15px;
    }

    &__card {
        padding: 15px;
        border: solid 1px $light-blue;
        border-radius: 8px;

        &_header {
            display: flex;
            align-items: center;

            a {
                text-decoration: none;
                cursor: pointer;
            }

            h3 {
                color: $light-blue;
                font-size: 20px;
            }

            p{
                margin-left: 20px;

                color: $grey;
            }
        }

        &_subheader {
            color: $black;
            letter-spacing: 1px;
            padding: 5px 3px;
        }

        &_content {
            margin-left: 10px;
            ul {

                li {
                    padding: 5px 0;
                }
            }
        }
    }
}

.Skills {
    &__subtitle {
        @include titles;
        margin-top: 30px;
        font-size: 26px;
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(100px, 1fr));

        &_element {
            display: flex;            
            flex-direction: column;
            align-items: center;
            padding: 10px 0;
            font-size: 30px;
            color: $dark-blue;

            p {
                font-size: 24px;
                color: $black;

                @media (max-width: 550px) {
                    font-size: 18px;
                }
            }
        }
    }
}