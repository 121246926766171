.Modal {
    border-radius: 10px;
    overflow: hidden;
    background-color: white;
    width: 60%;
    position: fixed;
    top: 50vh; left: 50vw; transform: translate(-50%, -50%);    
    -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.81); 
    box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.81);

    &__header {
        color: $light-blue;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 20px;
        button {
            border: none;
            background: transparent;
            font-size: 25px;
            cursor: pointer;
        }
    }

    &__body {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        padding: 10px;
        img {
            max-width: 100%;
            border-radius: 7px;
        }

        p{
            padding: 0 10px;
        }
    }

    &__footer {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(50px, 1fr));
        padding: 10px 0 0 0;
        border-top: solid 1px $black;

        a{
            margin: 0;
            padding: 0;
            display: flex;
            justify-content: center;
            align-items: center;
        }

        button {
            border: none;
            padding: 0 0 10px 0;
            font-size: 20px;
            cursor: pointer;
            background: transparent;
            transition: ease .5s;

            &:hover {
                font-size: 24px;
            }
        }

    }

    &__button {
        padding: 15px;
        border: solid 1px $light-blue;
        border-radius: 10px;

        img {
            display: block;
            margin: auto;
            max-width: 100%;
        }

        h3{
            text-align: center;
        }

        &:hover {
            padding: 5px;
            transition: ease .5s;
            border: none;
            -webkit-box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.81); 
            box-shadow: 0px 0px 17px 0px rgba(0,0,0,0.81);

            h3 {
                font-size: 20px;
            }
        }
    }

    @media (max-width: 500px) {
        width: 360px;

        &__body {
            grid-template-columns: 1fr;
            gap: 15px;
        }
    }
}