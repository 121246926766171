.Portfolio {
    @include sectionFormat;

    &__title {
        @include titles;        
    }

    &__grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
        gap: 25px;
    }
}